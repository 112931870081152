import React from 'react';
import { Link } from 'gatsby';


export const DocsPreview = () => {
  return (
    <div className="text-light py-5" style={{ minHeight: '100vh', background: 'rgba(26,32,44,1)' }}>
      <div className="container">
        <div className="hero mt-5">
          <h4 className="text-center font-bold text-4xl mb-3">Well Documented Components</h4>
          <h2 className="font-weight-bold text-center mb-4">Do more with reusable components</h2>
          <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
            {' '}
            All our components have been well documented, showcasing the different ways the
            components can be combined to give different results.
          </h5>
          <img
            alt="docsImage"
            width="100%"
            height="auto"
            src={require('../../../components/images/adocumentation.png')}
          />
          <p className="text-center mt-2">
            View full <Link to="/docs/contrast/react/index/">documentation here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
