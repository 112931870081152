import React from 'react';
import { Link } from 'gatsby';


export const Feature = () => {
  return (
    <div className="container">
    <div className="feature feature-2">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../../components/images/adminfeatfree.png')}
            alt="#"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 showcase__lead">
            Dashboards created to showcase all your important information in a glance
          </h2>
          <p className="feature__text">
            Contrast admin dashboard was created with components that lets the user 
            see very vital and important information in a glance. 
            It was designed with different types of components used to showcase different types of data to the user.
          </p>
          <ul className="feature-2__list">
            <li>
              Freely tweak the dashboard to suit your personal needs by easily editing some of the components using the contrast documentation.
            </li>
          </ul>
          <Link to="/app/downloads" className="btn1 btn__inverse">
            Get Admin Dashboard
          </Link>
        </div>
      </div>
      </div>
      </div>
  );
};
