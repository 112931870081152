import React from 'react';
import { Link } from "gatsby";

export const About = () => {
  return (
     <div  className="container">
    <div className="feature">
      <div className="feature__wrap">
        <div className="feature__left">
         
            <h2 className="feature__lead feature__lead--1 hero__lead">
              Give your admin website/apps a refreshing look
          </h2>
            <p className="feature__text">
              The Contrast admin template was built from the ground up with the end users in mind.
              We made sure to make the interface very intuitive. Get started using the Contrast admin dashboard today for your various projects.
         </p>
            <p className="feature__text">
              We also designed a Contrast Admin Pro Template that we now get for free when you purchase a Contrast Pro License to give you access to more amazing features and improvements. Have a look.
        </p>
            <div className="feature__buttons">
              <Link to="/product/contrast-pro/" className="btn1 btn__inverse">
                <span className="btn__text">Get Pro Admin Template</span>
              </Link>
            </div>
        </div>
        <div className="feature__right">
            <img
              src={require('../../../components/images/adminlastfree.png')}
              alt="#"
              className="feature__img"
            />
          
        </div>
      </div>
      </div>
    </div>
  );
};
